@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  background-color: #f8f8f8;
}

h1, h2, h3 {

 font-weight: 400;
}

h1 {
  font-size: 64px;
}

p {
  margin: 0px auto;
  line-height: 120%;
}

.secondarybtn {
  padding: 8px 12px;
  border: 2px solid #0002;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
}

.wrapper {
  max-width: 800px;
  width: 85%;
  margin: 0px auto;
}

.hero {
  margin: 80px 0px;
}

.logo {
  width: 160px;
}

.container {
  padding: 40px 24px;
  border-radius: 12px;
  border: 2px solid #eee;
  box-shadow: 0px 2px 0px #eee;
  background-color: #fff;
  margin-bottom: 24px;
}

.sectionheadline {
  margin-bottom: 16px;
  margin-top: 40px;
}

.containergrid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.databox {
  text-align: center;
}

.data-val {
  font-size: 56px;
  font-weight: bold;
}

.data-label {
  font-size: 12px;
}

.trailbox {
  border-radius: 12px;
  border: 2px solid #eee;
  box-shadow: 0px 2px 0px #eee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s, border 0.2s;
}

.trailbox:hover {
  box-shadow: 0px 4px 0px #ddd;
  border: 2px solid #ddd;
}

.trailboximg {
  width: 200px;
  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 0px 0px 10px;
}

.trailboxtext {
  flex: 1;
  padding: 48px 32px;
}

.trailboxarrow {
  width: 24px;
  position: absolute;
  bottom: 20px;
  right: 32px;
  opacity: 0;
  transition: right 0.2s, opacity 0.2s;
}

.trailbox:hover .trailboxarrow {
  right: 20px;
  opacity: 0.6;
}

.trailboxdatagrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 24px;
  width: fit-content;
  column-gap: 12px;
}

.trailboxdatabox {
  text-align: center;
  width: 110px;
}

.trailboxdata-val {
  font-size: 40px;
  font-weight: bold;
}

.trailboxdata-label {
  font-size: 12px;
}

.val-smalltext {
  font-size: 60%;
  line-height: 1;
}

.livebadge {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #dbecce;
  font-size: 12px;
  border-radius: 40px;
  padding: 4px 12px 4px 8px;
  color: #406619;
  display: inline-flex;
  align-items: center;
}

.livebadge img {
  width: 12px;
  height: 12px;
  display: block;
  margin-right: 4px;
  animation: livedot ease-in-out 1s infinite alternate;
}

@keyframes livedot {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}

.star-rating {
  position: relative;
  display: inline-block;
  width: 118px; /* Adjust to the width of your 5-star image */
  height: 20px; /* Adjust to the height of your 5-star image */
}

.stars-outer {
  position: relative;
  display: inline-block;
  background: url('./img/stars-grey.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover; /* Ensure the image covers the full width and height */
}

.stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 100%;
  background: url('./img/stars.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  overflow: hidden;
}

.feedbackitem {
  border-radius: 12px;
  border: 2px solid #eee;
  box-shadow: 0px 2px 0px #eee;
  background-color: #fff;
  margin-bottom: 12px;
  position: relative;
  padding: 20px;
  font-size: 14px;
}

.feedbackitem-trailimg {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 8px;
}

.feedbackitem-rating {
  font-weight: bold;
  margin-right: 8px;
}

.feedbackitem-feedback {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 16px;
}

.feedbackitem-titlebox {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 12px;
}

.feedbackitem-date {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  opacity: 0.7;
}

.page-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container {
  border-radius: 12px;
  border: 2px solid #eee;
  box-shadow: 0px 2px 0px #eee;
  background-color: #fff;
  position: relative;
  padding: 32px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  margin-bottom: 40px;
}

.login-logo {
  width: 200px;
}

.login-container label {
  display: block;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 14px;
}

.login-container input {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  outline: none;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  font-family: inherit;
}

.login-button {
  display: block;
  color: #fff;
  background-color: #000;
  border-radius: 8px;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.logoutbutton {
  font-size: 14px;
  cursor: pointer;
}

.loadingcont {
  width: 100%;
  text-align: center;
  margin-top: 120px;
}

.loadingcont img {
  width: 40px;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {rotate: 0deg;}
  to {rotate: 360deg;}
}

.button {
  display: inline-block;
  padding: 6px 12px;
  background-color: #eee;
  border-radius: 8px;
}


/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow-y: scroll;
  /*backdrop-filter: blur(8px);*/
}

.modalbackground {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0008;
}

.modalclosed {
  display: none;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 12px;
  width: 85%;
  max-width: 800px;
  position: relative;
  margin: 0px auto;
    margin-top: 100px;
    margin-bottom: 100px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  font-family: inherit;
  background-color: #eee;
  padding: 6px 12px;
  border-radius: 8px;
}

.containertwocolumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  width: 100%;
}

.stationbox {
margin-bottom: 32px;
margin-top: 32px;
}

.stationbox-bar {
  height: 8px;
  width: 100%;
  background-color: #ddd;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 12px;
}

.stationbox-bar-inner {
  background-color: #5166c0;
  height: 100%;
}

.stationboximage {
  width: 40px;
  height: 40px;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
}

.stationboxtext {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stationboxtitle {
  display: flex;
  align-items: center;
}

.stationboxtitle h3 {
  margin-left: 12px;
}

.modal-content .trailboxdatabox {
  text-align: center;
  width: 100%;
  border: 2px solid #eee;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content .trailboxdatagrid {
  width: auto;
}

.modal-content .trailheadline {
  font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
}

.tiptext {
  margin-top: 16px;
  margin-bottom: 12px;
  font-size: 14px;
}

.ratingdetailbox {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 40px;
}

.ratingdetailbox .trailboxdata-val {
  font-size: 80px;
}

.ratingdetailbox .trailboxdata-label {
  font-size: 16px;
  margin-bottom: 12px;
}

.ratingdetail-bar {
  height: 12px;
  width: 100%;
  background-color: #eee;
  border-radius: 16px;
  overflow: hidden;
}

.ratingdetail-bar-inner {
  background-color: #ffca1a;
  height: 100%;
}

.ratingdetailbarcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.ratingdetailbarcontainer p {
  width: 24px;
  margin-right: 8px;
  font-size: 16px;
}

.feedbackheadlinesection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.filterbubble {
  padding: 4px 8px;
  border: 2px solid #eee;
  display: inline-block;
  border-radius: 12px;
  cursor: pointer;
  margin: 4px;
  background-color: #fff;
}

.filterbubbleactive {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
}

.filtercontainer {
  background-color: #f8f8f8;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 40px;
}

.filterbox {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid #eee;
}

.filterbox p {
  font-size: 16px;
  margin-bottom: 6px;
}

.inactivebadge {
  display: inline-block;
  background-color: #eee;
  color: #222;
  padding: 4px 8px;
  border-radius: 8px;
  border-left: 3px solid #aaa;
}